import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import Popup from "../../../../Popup/Popup";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CustomCheckbox from "../../../../InputFields/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import { validateMobileNumber, validateMobileOtp } from "../../../../../SupportingFiles/HelpingFunction";
import { toast } from "react-toastify";
import { ACCOUNT_SERVICES } from "../../../../../Services/Account/AccountServices";
import { UserProfileSlice } from "../../../../../Store/Reducer/Account/UserProfileSlice";
import { SESSION_CONSTANTS } from "../../../../../Services/Constants/SessionConstants";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { useDispatch } from "react-redux";
import { FRONTEND_DOMAIN } from "../../../../../URLCollection/Domain";
import { MY_ACCOUNT_ROUTES } from "../../../../../Routing/Path/MyAccountRoutes";

interface Props {
  open: boolean;
  setOpenLoginPopup: Function;
}

const LoginPopup: React.FC<Props> = ({ open, setOpenLoginPopup }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [loginSteps, setLoginSteps] = useState<"1" | "2">("1");

  const [loginFields, setLoginFields] = useState<{
    otp: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
  }>({
    otp: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });

  const updateMasterState = (attrName: string, value: any) => {
    setLoginFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };


  const [showmobileSection, setShowmobileSection] = useState(true);
  // const [loginFields, setLoginFields] = useState<{
  //   mobile: { value: string; warning: boolean };
  //   otp: { value: string; warning: boolean };
  // }>({
  //   mobile: { value: "", warning: false },
  //   otp: { value: "", warning: false },
  // });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  // const closeModal = () => {
  //   setOpen(false);
  //   setShowEnterMobileSection(true);
  //   setLoginFields({
  //     enterMobile: { value: "", warning: false },
  //     enterOtp: { value: "", warning: false },
  //   });
  // };

  const handleShowMessage = () => {
    setLoginSteps("2")
    let hasError = false;
    let data = { ...loginFields };
    if (!validateMobileNumber(data.mobile.value)) {
      hasError = true;
      data = {
        ...data,
        mobile: { value: data.mobile.value, warning: true },
      };
    }
    setLoginFields({ ...data });
    if (!hasError) {
      postMobileNum();
    }
  };

  const postMobileNum = () => {
    let dto = {
      type: "myAccount",
      pno: loginFields.mobile.value,
    };
    const onSuccess = (res: any) => {
      console.log("resOTP", res);

      if (res) {
        setLoginFields({
          ...loginFields,
          otp: { value: "", warning: false },
        });
        if (!res.status) {
          setShowmobileSection(false);
        } else {
          toast.error("Please check your Mobile number");
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCOUNT_SERVICES.accountLogin(onSuccess, onError, dto);
  };

  const showMessage = () => {
    let hasError = false;
    let data = { ...loginFields };
    if (!validateMobileOtp(data.otp.value)) {
      hasError = true;
      data = {
        ...data,
        otp: { value: data.otp.value, warning: true },
      };
    }
    setLoginFields({ ...data });
    if (!hasError) {
      verifyOtp();
    }
  };

  const verifyOtp = () => {
    let dto = {
      pno: loginFields.mobile.value,
      otp: loginFields.otp.value,
    };

    const onSuccess = (res: any) => {
      console.log(res);

      if (res) {
        if (!res.status && res.message === "Otp verify success") {
          // closeModal();
          dispatch(
            UserProfileSlice.actions.setAccountUserDataMobile(
              loginFields.mobile.value
            )
          );
          const encodedMobile = btoa(loginFields.mobile.value);
          sessionStorage.setItem(
            SESSION_CONSTANTS.USER_MOBILE_NO,
            encodedMobile
          );
          const newUrl = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
          // const newUrl = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${loginFields.enterMobile.value}`;

          // Redirect to the new URL
          window.location.href = newUrl;
        } else {
          toast.error(res.message);
        }
      }
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCOUNT_SERVICES.accountLoginVerifyOtp(onSuccess, onError, dto);
  };

  const resendOtp = () => {
    let dto = {
      type: "myAccount",
      pno: loginFields.mobile.value,
    };
    const onSuccess = (res: any) => {
      console.log(res);

      if (res) {
        setLoginFields({
          ...loginFields,
          otp: { value: "", warning: false },
        });
        if (!res.status) {
          toast.success("OTP sent successfully");
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCOUNT_SERVICES.accountLoginResendOtp(onSuccess, onError, dto);
  };


  return (
    <Popup
      open={open}
      handleClose={() => {
        setLoginSteps("1");
        setOpenLoginPopup(false);
      }}
      variant="medium"
      title={loginSteps === "1" ? "Customer Login" : "One Time Password"}
      content={
        <Box padding="24px">
          {loginSteps === "1" ? (
            <Box>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid item xs={12} marginBottom={""}>
                  <img src="./images/login_popup_img1.svg" alt="" />
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    Hey, Enter your details to get sign in to your account
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter mobile number"}
                    value={loginFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={loginFields.mobile.warning}
                    error_message={"Enter mobile number"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <CustomButton
                    className="primaryBtn mb-2"
                    text="Send OTP"
                    onClick={() => {
                      handleShowMessage();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : loginSteps === "2" ? (
            <Box>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid item xs={12} marginBottom={""}>
                  <img src="./images/login_popup_img2.svg" alt="" />
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    OTP sent successfully on{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      +91-{loginFields.mobile.value}
                    </span>
                    <Link
                      sx={{
                        background: "rgba(242, 242, 242, 1)",
                        fontSize: "12px",
                        padding: "2px 10px",
                        borderRadius: "40px",
                        color: "rgba(51, 51, 51, 1)",
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                      onClick={() => setLoginSteps("1")}
                    >
                      Edit
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter OTP"}
                    value={loginFields.otp.value}
                    attrName={"otp"}
                    value_update={updateMasterState}
                    warn_status={loginFields.otp.warning}
                    error_message={"Enter OTP"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <CustomButton
                    className="primaryBtn mb-2"
                    text="Verify OTP"
                    onClick={() => {
                      showMessage();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Didn’t receive? <Link sx={{ color: COLORS.primary, fontWeight: 600 }}>Resend OTP</Link>
                  </p>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Box>
      }
    />
  );
};

export default LoginPopup;
