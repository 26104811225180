import { PHP_DOMAIN, STRAPI_DOMAIN } from "../../URLCollection/Domain";

export const CommonURLs = {
  // GENERATE_TOKEN: `${Domain1}/api/users/generate-token.json`,
  GENERATE_TOKEN: `${PHP_DOMAIN}/api/users/generate-token.json`,

  //strAPI
  LOAD_HOMEPAGE_DATA: `${STRAPI_DOMAIN}/api/home-page?populate=deep`,
  CMS_DATA: `${STRAPI_DOMAIN}/api/cms-page?populate=deep`,
  PRODUCT_PAGE_DATA: `${STRAPI_DOMAIN}/api/prod-page?populate=deep`,
};
